<template>
  <ManageCreativeSegment dialog-name="Creative Segments" />
</template>
<script>
import ManageCreativeSegment from '@/views/app/manage/CreativeSegment'

export default {
  name: 'CreativeSegmentWrapper',
  components: {
    ManageCreativeSegment
  }
}
</script>
